<template>
    <div></div>
</template>
<script>


import axios from 'axios';
export default {
    name: 'ContactForm',
    components: {
        //premiumCard:Boolean
    },
    props: {
        //data: Object
    },
    data: function(){
      return{
        
        //data: {}
      }
    },
    mounted: function(){
      
      console.log("contactform module");
      document.addEventListener( 'wpcf7submit', function( event ) {
      var inputs = event.detail.inputs;
      console.log(inputs);
        // axios.post("/api?action=register-company",event.detail.inputs).then(function(response){
        //   console.log(response);
        // });

        axios.post("/"+window.location.search,{json:event.detail.inputs}).then(function(response){
          console.log(response);
          var enps = "";
          var fs = "";
          for(var input of event.detail.inputs){
            if(input.name == "eqi-enps"){
              enps = input.value;
            }
            if(input.name == "eqi-frequenzy"){
              fs = input.value;
            }
          }
          document.location = "/results/?es="+enps+"&fs="+fs;
          
        });
      // for ( var i = 0; i < inputs.length; i++ ) {
      //   if ( 'your-name' == inputs[i].name ) {
      //     alert( inputs[i].value );
      //     break;
      //   }
      // }
    }, false );

    },
    methods: {
      
      
    }
  }
  </script>