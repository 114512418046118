<template>
  <div>
    <!-- Button to open modal -->
    <button type="button" class="btn btn-primary" @click="showModal">
      Update eNPS Score
    </button>

    <!-- Modal -->
    <div class="modal fade" id="updateUserModal" tabindex="-1" aria-labelledby="updateUserModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="updateUserModalLabel">Update User</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- Your form here -->
            <input v-model="user.enps" type="number" class="form-control mb-3" placeholder="New eNPS score">
            <input v-model="user.answerFrequenzy" type="number" class="form-control" placeholder="Answer Frequency">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="updateUser">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>

    
</template>
    
    <script>
    import axios from 'axios'
    import 'bootstrap'
    export default {
      name: 'UpdateUser',
      props: {
        userID:Number
      },
      data() {
        return {
          user:{
            id:0,
            enps:0,
            answerFrequenzy:0
          }
        };
      },
      created(){
        this.user.id = this.userID;
      },
      methods: {
        showModal(){
          const modalElement = document.getElementById('updateUserModal');
          const modalInstance = new bootstrap.Modal(modalElement);
          modalInstance.show();
        },
        updateUser(user) {
          // Implement your update logic here.
          axios.post('/api/?action=updateuser', user)
          .then(response => {
            // Here you can handle the response from the server
            console.log('User updated successfully', response.data);
          })
          .catch(error => {
            // Here you handle the error if the request failed
            console.error('Error updating user', error);
          });
          // You might want to emit an event or call an API to update the user data
        }
      },
    };
    </script>
    