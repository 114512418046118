<template>
  <div>
    <input class="form-control mb-2" type="text" placeholder="Search by email or name" v-model="query"/>
    <p>
      Companies: {{ user_data.length }}
    </p>
    <table class="table">
      <thead>
        <tr>
          <th @click="sortTable('ID')" style="width: 50px;">ID</th>
          <th @click="sortTable('name')" style="width: 150px;">Name</th>
          <th @click="sortTable('user_email')" style="width: 200px;">Email</th>
          <th @click="sortTable('roles')" style="width: 100px;">Role</th>
          <th @click="sortTable('enps')" style="width: 70px;">Enps</th>
          <th @click="sortTable('frequenzyScore')" style="width: 120px;">Frequency Score</th>
          <th @click="sortTable('nps')" style="width: 70px;">Nps</th>
          <th @click="sortTable('nps_frequenzy')" style="width: 150px;">Nps Frequency Score</th>
          <th @click="sortTable('supplier')" style="width: 75px;">Supplier</th>
          <th @click="sortTable('user_registered')" style="width: 75px;">User registered</th>
          <th style="width: 100px;"></th>
          <th style="width: 100px;"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="user in user_data" :key="user.ID">
          <tr v-if="query == '' || user.user_email.includes(query) || user.first_name.includes(query) || user.last_name.includes(query)">
            <td>{{ user.ID }}</td>
            <td>{{ user.first_name }} {{ user.last_name }}</td>
            <td>{{ user.user_email }}</td>
            <td>{{ user.roles }}</td>
            <td><input type="number" v-model="user.enps" /></td>
            <td><input type="number" v-model="user.frequenzyScore" /></td>
            <td><input type="number" v-model="user.nps" /></td>
            <td><input type="number" v-model="user.nps_frequenzy" /></td>
            <td><input type="text" v-model="user.supplier" /></td>
            <td>{{ user.user_registered }}</td>
            <td><button @click="updateUser(user)">Update</button></td>
            <td><button @click="deleteUser(user)">Delete</button></td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
    name: 'UsersTable',
    props: {
      users: {
        type: Array,
        default: () => [],
      },
      title: String,
      body: String,
    },
    data() {
      return {
        user_data:[],
        query: "",
        currentSort: '',
        sortDirection: 'asc'
      };
    },
    created(){
      this.user_data = this.users;
      console.log(this.users);
    },
    methods: {
      updateUser(user) {
        // Implement your update logic here.
        axios.post('/api/?action=updateuser', user)
        .then(response => {
          // Here you can handle the response from the server
          console.log('User updated successfully', response.data);
        })
        .catch(error => {
          // Here you handle the error if the request failed
          console.error('Error updating user', error);
        });
        // You might want to emit an event or call an API to update the user data
      },
      deleteUser(user) {
        // Implement your update logic here.
        axios.post('/api/?action=deleteuser', user)
        .then(response => {
          // Here you can handle the response from the server
          console.log('User updated successfully', response.data);
          const index = this.user_data.findIndex(u => u.id === user.id);
      
          // Check if the user was found.
          if (index !== -1) {
            // Remove the user from the array.
            this.user_data.splice(index, 1);
          }
        })
        .catch(error => {
          // Here you handle the error if the request failed
          console.error('Error updating user', error);
        });
        // You might want to emit an event or call an API to update the user data
      },
      sortTable(column) {
        if (this.currentSort === column) {
          this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
          this.currentSort = column;
          this.sortDirection = 'asc';
        }

        this.user_data.sort((a, b) => {
          let valA = a[column];
          let valB = b[column];

          // Convert to numbers if the column is 'enps' or 'frequenzyScore'
          if (column === 'enps' || column === 'frequenzyScore' || column === 'nps' || column === 'nps_frequenzy') {
            valA = parseInt(valA);
            valB = parseInt(valB);
          }

          if (valA < valB) {
            return this.sortDirection === 'asc' ? -1 : 1;
          }
          if (valA > valB) {
            return this.sortDirection === 'asc' ? 1 : -1;
          }
          return 0;
        });
      }
    },
  };
  </script>
  