<template>
    <figure class="highcharts-figure">
        <p v-if="data == null">Not available</p>
        <div id="container-rpm" class="chart-container"></div>
    </figure>
</template>
<script>
import * as Highcharts from 'highcharts/highcharts';
    import * as HighchartsMore from 'highcharts/highcharts-more';
    import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

    // Now init modules:
    HighchartsMore(Highcharts);
    HighchartsSolidGauge(Highcharts);
export default {
    name: 'EQiPosition',
    components: {
        //premiumCard:Boolean
    },
    props: {
        //data: Object
        title:String,
        body:String
    },
    data: function(){
      return{
        premiumCard: false,
        freeCard:false,
        data: null
      }
    },
    mounted: function(){
        const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
        });
        var self = this;
        if(window.enps){
            self.data = window.enps;
            self.InitChart(window.enps);
        }
        else if(params.es){

        
            var base_url = "https://eqi-app.herokuapp.com";
            var url = base_url + "/api/enps/get-ranking/?enps=" + params.es;
            if(params.demo) url = url + "&demo="+params.demo;
        fetch(url).then(response => {
            return response.json();
        }).then(json => {
                self.data = json;
                self.InitChart(json);
        }).catch(function() {
        });
        }

    },
    methods: {
      InitChart(data){
        var gaugeOptions = {
    chart: {
        type: 'solidgauge',
        backgroundColor:'transparent',
        height:'100%'
    },

    title: null,

    pane: {
        center: ['50%', '70%'],
        size: '100%',
        //innerSize:'20%',
        startAngle: -100,
        endAngle: 100,
        background: {
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#000',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
        }
    },

    exporting: {
        enabled: false
    },

    tooltip: {
        enabled: false
    },

    // the value axis
    yAxis: {
        stops: [
            [0.1, '#DF5353'], // green
            [0.5, '#DDDF0D'], // yellow
            [0.9, '#55BF3B'] // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
            y: -70
        },
        labels: {
            y: 16,
            style: {
                color: '#fff'
            }
        }
    },
    // xAxis: {
    //     labels: {
    //         style: {
    //             color: '#fff'
    //         }
    //     }
    // },
    plotOptions: {
        solidgauge: {
            dataLabels: {
                y: 5,
                borderWidth: 0,
                useHTML: true
            }
        }
    }
};
Highcharts.chart('container-rpm', Highcharts.merge(gaugeOptions, {
    yAxis: {
        reversed:true,
        min: data.total,
        max: 1,
        title: {
            text: null
        },
        stops: [
            [66, '#55BF3B'], // green
            [20, '#DDDF0D'], // yellow
            [1,'#DF5353'] // red
        ],
        labels:{
            enabled:false
        }
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'position',
        data: [data.eqiRanking],
        dataLabels: {
            color:'#fff',
            format:
                '<div style="text-align:center">' +
                '<h2 style="font-size:25px">{y}</h2><br/>' +
                '<span style="font-size:12px;opacity:0.4">' +
                '</span>' +
                '</div>'
        },
        // tooltip: {
        //     valueSuffix: ' revolutions/min'
        // }
    }]

}));
      }
      
    }
  }
  </script>