<template>
    <div>
        <template v-if="enps == null">
            <p>Not available</p>
        </template>
        <template v-if="enps >= -100 && enps <= -11">
            <h4>Your leadership level is Traditional (eNPS -100 to -11)</h4>
            <hr>
            <p>
            The company's development may be at a standstill and could be described as slightly passive. It can be difficult to modernize and compete with new ideas/ initiatives. The company should maybe focus on improving its internal communication, insight (incl. the awareness of its competencies), and engagement. Some employees might feel like the company is in a survival mode lacking a long-term vision. 
            </p>
            <p v-if="result && result.traditional > 1">
                You share leadership level with {{ result.traditional }} other companies
            </p>
        </template>
        <template v-if="enps >= -10 && enps <= 30">
            <h4>Your leadership level is Insightful (eNPS -10 to +30)</h4>
            <hr>
            <p>
            The company may have a good strategy but lack an open dialogue. It has an okay insight understanding of their strengths and weaknesses however its ability to quickly implement changes and growth can be improved.
            </p>
            <p v-if="result && result.insightful > 1">
                You share leadership level with {{ result.insightful }} other companies
            </p>
        </template>
        <template v-if="enps >= 31 && enps <= 50">
            <h4>Your leadership level is Opportunistic (eNPS +31 to +50)</h4>
            <hr>
            <p>
            The company is very proud (possibly of their product or brand) and has the drive to expand, mostly to achieve short-term goals (possibly also using a bonus system?). The company follows a clear strategy, and practices well-working behaviors such as communication, and self-awareness. The company knows its "What" and "How" but is maybe unsure of their united "Why" (the company's main purpose and influence on the world).
            </p>
            <p v-if="result && result.opportunistic > 1">
                You share leadership level with {{ result.opportunistic }} other companies
            </p>
        </template>
        <template v-if="enps >= 51 && enps <= 100">
            <h4>Your leadership level is Tier 1 - Leader (eNPS +51 to +100)</h4>
            <hr>
            <div style="display: flex;">
              <p style="width:50%; display:block;">
                The company may have a good strategy but lack an open dialogue. It has an okay insight understanding of their strengths and weaknesses however its ability to quickly implement changes and growth can be improved.

              To gain perspective, companies like Spotify, Netflix, Hubspot, and Salesforce tend to be in the “Leader” stage whilst IKEA, Disney, Home Depot & McDonald’s are “Insightful” (an important factor here is their large company size).

              Please note that these phases are very simplified and you might identify your company culture with different behaviors from several stages. An eNPS can be influenced by the size of a company (usually the larger the organization, the lower the eNPS), the industry, location, global trends, and internal circumstances (such as dramatic company changes).

              The graph has been assembled using research from sources used by Forbes and several leadership setups (such as Maslow).
              </p>
              
              <img
                  v-bind:src="isHovered ?  'https://eqinitiative.com/wp-content/uploads/2023/07/Tri_Compare.png' : 'https://eqinitiative.com/wp-content/uploads/2023/07/Tri_Tier1.png'"
                  alt="Image"
                  style="width:30%;"
                  v-on:mouseover="isHovered = true"
                  v-on:mouseout="isHovered = false"
                />
            </div>
            <p v-if="result && result.leader > 1">
                You share leadership level with {{ result.leader }} other companies
            </p>
        </template>
        <p>
            To gain perspective, companies like Spotify, Netflix, Hubspot, and Salesforce tend to be in the "Leader" stage whilst IKEA, Disney, Home Depot & McDonald's are "Insightful" (an important factor here is their large company size). 

            Please note that these phases are very simplified and you might identify your company culture with different behaviors from several stages. 
        </p>
        <p>
            An eNPS can be influenced by the size of a company (usually the larger the organization, the lower the eNPS), the industry, location, global trends, and internal circumstances (such as dramatic company changes). 
            The graph has been assembled using research from sources used by Forbes and several leadership setups (such as Maslow). 
        </p>
        
    </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'LeadershipNew',
  components: {},
  props: {},
  data() {
    return {
      enps: null,
      result: null,
      isHovered: false
    };
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('es')) {
      this.enps = params.get('es');
      this.fetchResult();
    }
  },
  methods: {
    async fetchResult() {
      try {
        const response = await axios.get('https://api.eqindex.org/api/enps/get-statistic');
        this.result = response.data;
      } catch (error) {
        console.error('Failed to fetch result:', error);
      }
    },
  },
};
</script>
