<template>
    <figure class="highcharts-figure">
        <p v-if="data == null">Not available</p>
        <div id="container-branch-position" class="chart-container" style="padding-bottom:20px;"></div>
    </figure>
</template>
<script>
import * as Highcharts from 'highcharts/highcharts';
    import * as HighchartsMore from 'highcharts/highcharts-more';
    import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
    import HighchartsColor from 'highcharts/modules/coloraxis';
    HighchartsColor(Highcharts);

    // Now init modules:
    HighchartsMore(Highcharts);
    HighchartsSolidGauge(Highcharts);

    


export default {
    name: 'BranchPosition',
    components: {
        //premiumCard:Boolean
    },
    props: {
        //data: Object
        title:String,
        body:String
    },
    data: function(){
      return{
        premiumCard: false,
        freeCard:false,
        data: null
      }
    },
    mounted: function(){
        const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
        });
        var self = this;
        if(window.enps){
            self.data = window.enps;
            self.InitChart(window.enps);
        }
    },
    methods: {
      InitChart(data){
        const gradientColors = [
    ['#45C5C3', '#0A9B99'], // Gradient for the 'position' series
    ['#9B6A9B', '#592B55']  // Gradient for the 'Same size average' series
    ];
        var gaugeOptions = {
    chart: {
        type: 'column',
        backgroundColor:'transparent',
        height:'100%'
    },
    layout: 'vertical',
    title: null,

    
    exporting: {
        enabled: false
    },

    tooltip: {
        enabled: true
    },

    // the value axis
    yAxis: {
        opposite:true,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        
        labels: {
                    y: 16,
                    style: {
                        color: '#fff'
                    },
                    format: '{value:.1f}' // Round values to one decimal place
                }
    },
    xAxis: {
        labels: {
            style: {
                color: '#fff'
            }
        }
    },
    
};
Highcharts.chart('container-branch-position', Highcharts.merge(gaugeOptions, {
    yAxis: {
        visible:true,
        title: {
            text: null
        },
        labels:{
            enabled:true
        }
    },
    credits: {
        enabled: false
    },
    plotOptions: {
        column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
            //pointWidth:100,
            dataLabels: {
                enabled: true
            }
        }
    },
    series: [{
        name: 'Industry Average',
        data: [data.branchAverage],
        pointWidth:100,
        pointPadding: 0,
        dataLabels: {
            color:'#fff',
            format:
                '<div style="text-align:center">' +
                //'<h2 style="font-size:25px">{y}</h2><br/>' +
                '<span style="font-size:12px;">{y}' +
                '</span>' +
                '</div>'
        },
        color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, gradientColors[1][0]], // Start color
                    [1, gradientColors[1][1]]  // End color
                ]
            }
    },{
        name: 'Your eNPS',
        data: [data.value],
        pointWidth:80,
        pointPadding: 0.4,
        dataLabels: {
            color:'#fff',
            format:
                '<div style="text-align:center">' +
                //'<h2 style="font-size:25px">{y}</h2><br/>' +
                '<span style="font-size:12px;">{y}' +
                '</span>' +
                '</div>'
        },
        color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, gradientColors[0][0]], // Start color
                    [1, gradientColors[0][1]]  // End color
                ]
            }
    }]

}));
      }
      
    }
  }
  </script>