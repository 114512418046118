//import Vue from 'vue'
//import { createApp ,h} from 'vue'
import { createApp} from 'vue'
//import EnpsScore from "./components/EnpsScore.vue";
import EqindexApp from "./EqindexApp.vue";
import EnpsScore from './components/EnpsScore.vue';
import AverageEnpsScore from './components/AverageEnpsScore.vue';
import EQiPosition from './components/EQiPosition.vue';
import BranchPosition from './components/BranchPosition.vue';
import BranchPositionAverage from './components/BranchPositionAverage.vue';
import SameSizePosition from './components/SameSizePosition.vue';
import SameSizePositionAverage from './components/SameSizePositionAverage.vue';
import Leadership from './components/Leadership.vue';
import LeadershipNew from './components/LeadershipNew.vue';
import FrequenzyScore from './components/FrequenzyScore.vue';
import FrequenzyPosition from './components/FrequenzyPosition.vue';
import PositionSize from './components/PositionSize.vue';
import PositionIndustry from './components/PositionIndustry.vue';
import Development from './components/Development.vue';
import DirectCompetition from './components/DirectCompetition.vue';
import ContactForm from './components/ContactForm.vue';
import Users from './components/Users.vue';
import UpdateUser from './components/UpdateUser.vue';
import NpsScore from './components/NpsScore.vue';
import NpsFrequenzyScore from './components/NpsFrequenzyScore.vue';
import NpsPosition from './components/NpsPosition.vue';
//import 'bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';




//import Development from "./components/Development.vue";

//import VueLazyLoad from 'vue-lazyload'
//import '@popperjs/core'

//Vue.config.productionTip = false

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')

var app = createApp({
    el:'#content'
    //EqindexApp,
    //render: ()=>h(EqindexApp)
});

app.component('eqindex-app', EqindexApp);
app.component('enps-score', EnpsScore);
app.component('average-enps-score', AverageEnpsScore);
app.component('eqi-position', EQiPosition);
app.component('branch-position', BranchPosition);
app.component('branch-position-average', BranchPositionAverage);
app.component('same-size-position', SameSizePosition);
app.component('same-size-position-average', SameSizePositionAverage);
app.component('leadership', Leadership);
app.component('leadership-new', LeadershipNew);
app.component('frequenzy-score', FrequenzyScore);
app.component('frequenzy-position', FrequenzyPosition);
app.component('position-size', PositionSize);
app.component('position-industry', PositionIndustry);
app.component('development', Development);
app.component('direct-competition', DirectCompetition);
app.component('contactform', ContactForm);
app.component('users', Users);
app.component('updateuser', UpdateUser);
app.component('nps-frequenzy-score', NpsFrequenzyScore);
app.component('nps-score', NpsScore);
app.component('nps-position', NpsPosition);
//app.component('development', Development);
//app.mount('#score-page');
//app.component("EnpsScore", EnpsScore);
app.mount('#content');
