<template>
<div>
    <div class="row justify-content-center">
        <EnpsScore v-if="data != null" :data="data"></EnpsScore>
        <EQiPosition v-if="data != null" :data="data"></EQiPosition>
    </div>
    <div class="row justify-content-center mt-3">
        <Leadership v-if="enps != null" :enps="enps"></Leadership>
    </div>
    <div class="row justify-content-center mt-3">
        <FrequenzyScore v-if="enps != null" :enps="enps"></FrequenzyScore>
        <FrequenzyPosition v-if="enps != null" :enps="enps"></FrequenzyPosition>
    </div>
    <div class="row justify-content-center mt-3">
        <PositionSize v-if="enps != null" :enps="enps"></PositionSize>
        <PositionIndustry v-if="enps != null" :enps="enps"></PositionIndustry>
    </div>
    <div class="row justify-content-center mt-3">
        <Development></Development>
    </div>
    <div class="row justify-content-center mt-3">
        <DirectCompetition></DirectCompetition>
    </div>
</div>
</template>
<script>
//import Highcharts from 'highcharts';
import EnpsScore from './components/EnpsScore.vue';
import EQiPosition from './components/EQiPosition.vue';
import Leadership from './components/Leadership.vue';
import FrequenzyScore from './components/FrequenzyScore.vue';
import FrequenzyPosition from './components/FrequenzyPosition.vue';
import PositionSize from './components/PositionSize.vue';
import PositionIndustry from './components/PositionIndustry.vue';
import Development from './components/Development.vue';
import DirectCompetition from './components/DirectCompetition.vue';
export default {
    name: 'EqindexApp',
    components: {
        //premiumCard:Boolean
        EnpsScore,
        EQiPosition,
        Leadership,
        FrequenzyScore,
        FrequenzyPosition,
        PositionSize,
        PositionIndustry,
        Development,
        DirectCompetition
    },
    props: {
        
    },
    data: function(){
      return{
        freeCard:false,
        enps:null,
        eqiRanking:0,
        total:0,
        data:null
      }
    },
    created: function(){
        const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
        });
        var self = this;
        var base_url = "https://eqi-app.herokuapp.com";
        var url = base_url + "/api/enps/get-ranking/?enps=" + params.es;
    fetch(url).then(response => {
        return response.json();
      }).then(json => {
          self.enps = json.value;
          self.eqiRanking = json.eqiRanking;
          self.total = json.total;
            //initCharts(json);
            self.data = json;
      }).catch(function() {
      });
    },
    methods: {
      
      
    }
  }
  </script>
<style lang="scss">
.benchmark{
	h1.title,hr.divider,h3{
		color:#fff;
	}
	
}
.faq-puff{
	border-radius:6px;
	padding:10px;
	padding-left:1rem;
	margin-bottom:10px;
	background-color:RGB(40, 40, 40,0.6);
	border:1px solid #32b7b5;
	a{
		color:#fff;
		text-decoration: none;
	}
}
.premium-puff{
	padding:2rem;
	text-align:left;
	background-color: #2b3d51;
	color:#fff;
	
	ul{
		list-style-type: disc;
		width:100%;
		font-size:16pt;
	}
	ul.leadership-legends{
		list-style-type: none;
		margin-bottom:1rem;
		li.leadership-legend{
			float:left;
			margin-right:1rem;
			i{
				margin-right:10px;
				&.traditional{
					color:#3e6865;
				}
				&.insightful{
					color:#0a9b99;
				}
				&.opportunists{
					color:#17b7b4;
				}
				&.leader{
					color:#45c5c3;
				}
				
			}
		}
	}
}
.free-puff{
	padding:2rem;
	text-align:left;
	background-color:RGB(255,255,255,0.08);
	color:#fff;
	// opacity: 0.08;;
	ul{
		list-style-type: disc;
	}
	.free-logo{
		width: 20%;
		margin: 0 auto;
		display: block;
		margin-bottom:1rem;
	}
}
</style>