<template>
    <figure class="highcharts-figure">
        <div id="development" class="chart-container"></div>
    </figure>
</template>
<script>
import * as Highcharts from 'highcharts/highcharts';
    import * as HighchartsMore from 'highcharts/highcharts-more';
    import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

    // Now init modules:
    HighchartsMore(Highcharts);
    HighchartsSolidGauge(Highcharts);
export default {
    name: 'Development',
    components: {
        //premiumCard:Boolean
    },
    props: {
        data: Object
    },
    data: function(){
      return{
        premiumCard: false,
        positionSizeCard:false
        //data: {}
      }
    },
    mounted: function(){
        
       
Highcharts.chart('development', {
    chart: {
        type: 'line',
        backgroundColor:'#2b3d51'
    },
    title: {
      text: null
    },
    subtitle: {
      text: null
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {
        style: {
            color: '#fff'
        }
        }
    },
    xAxis: {
      accessibility: {
        rangeDescription: 'Range: 2018 to 2029'
      },
      labels: {
        style: {
            color: '#fff'
        }
        }
    },
    legend: {
      layout: 'horizontal',
      align: 'right',
      horizontalAlign: 'middle',
      itemStyle: {
        color: '#fff'
        //fontWeight: 'bold'
        }
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        pointStart: 1
      }
    },
    series: [{
      name: 'Your eNPS score',
      data: [20,18,24,28,32],
      color:'#17b7b4',
      lineWidth:4
    },{
      name: 'The average eNPS score',
      data: [-2,4,6,8,4],
      color:'#b47bb4',
      lineWidth:4
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
    
    });
    },
    methods: {
      
      
    }
  }
  </script>