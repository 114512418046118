<template>
    <figure class="highcharts-figure">
        <div id="container-position-in-industry" class="chart-container"></div>
    </figure>
</template>
<script>
import * as Highcharts from 'highcharts/highcharts';
    import * as HighchartsMore from 'highcharts/highcharts-more';
    import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

    // Now init modules:
    HighchartsMore(Highcharts);
    HighchartsSolidGauge(Highcharts);
export default {
    name: 'PositionIndustry',
    components: {
        //premiumCard:Boolean
    },
    props: {
        data: Object
    },
    data: function(){
      return{
        premiumCard: false,
        positionIndustryCard:false
        //data: {}
      }
    },
    mounted: function(){
     //   var url = "";
    // if(companyId != undefined && companyId != "")
    // {
    //     url = base_url + "/api/enps/get-score-and-ranking/?companyId=" + companyId;
    // }
    // if(enps != undefined)
    // {
    //     var base_url = "https://eqi-app.herokuapp.com";
    //     url = base_url + "/api/enps/get-ranking/?enps=" + enps;
    // }
    // var base_url = "https://eqi-app.herokuapp.com";
    //     url = base_url + "/api/enps/get-ranking/?enps=" + 20;
    // fetch(url).then(response => {
    //     return response.json();
    //   }).then(json => {
    //       self.enps = json.value;
    //       self.eqiRanking = json.eqiRanking;
    //       self.total = json.total;
    //         //initCharts(json);
    //         self.data = json;
    //   }).catch(function() {
    //   });
        var gaugeOptions = {
    chart: {
        type: 'solidgauge',
        backgroundColor:'#2b3d51',
        height:'100%'
    },

    title: null,

    pane: {
        center: ['50%', '70%'],
        size: '100%',
        //innerSize:'20%',
        startAngle: -100,
        endAngle: 100,
        background: {
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#000',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
        }
    },

    exporting: {
        enabled: false
    },

    tooltip: {
        enabled: false
    },

    // the value axis
    yAxis: {
        stops: [
            [0.1, '#DF5353'], // green
            [0.5, '#DDDF0D'], // yellow
            [0.9, '#55BF3B'] // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
            y: -70
        },
        labels: {
            y: 16,
            style: {
                color: '#fff'
            }
        }
    },
    // xAxis: {
    //     labels: {
    //         style: {
    //             color: '#fff'
    //         }
    //     }
    // },
    plotOptions: {
        solidgauge: {
            dataLabels: {
                y: 5,
                borderWidth: 0,
                useHTML: true
            }
        }
    }
};
Highcharts.chart('container-position-in-industry', Highcharts.merge(gaugeOptions, {
    yAxis: {
        reversed:true,
        min: 0,
        max: 100,
        title: {
            text: null
        },
        stops: [
            [0.1,'#55BF3B' ], // green
            [0.5, '#DDDF0D'], // yellow
            [0.9,'#DF5353'] // red
        ]
    },

    credits: {
        enabled: false
    },

    series: [{
        name: 'Position',
        data: [18],
        dataLabels: {
            color:'#fff',
            format:
                '<div style="text-align:center">' +
                '<h2 style="font-size:25px">{y}</h2><br/>' +
                '<span style="font-size:12px;opacity:0.4">' +
                '</span>' +
                '</div>'
        },
        // tooltip: {
        //     valueSuffix: ' km/h'
        // }
    }]

}));

    },
    methods: {
      
      
    }
  }
  </script>